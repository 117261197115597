/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { pagination, Table } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { plusicon, refreshicon, searchnormal, error02, dangericon } from '../imagepath';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../../assets/css/style.css';
import '../../assets/ref_css/btn.css';
import clientApi from '../../services/ClientServices/ClientRoutes';
import StatusButton from '../../statusbutton';
import { useClient } from '../../context/ClientContext';
import { useLocation } from 'react-router-dom';

const ClientsList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const { setClientId } = useClient();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataSource, setDataSource] = useState([]);


  const fetchClients = async () => {
    try {
      const data = await clientApi.getClients();
      setDataSource(data.clients || []);
      setFilteredData(data.clients || []);
      setError(null);
    } catch (error) {
      setError(error);
      setDataSource([])
      setFilteredData([])
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    const filtered = dataSource.filter(client => {
      const fullName = `${client.first_name} ${client.last_name}`.toLowerCase();
      const searchValue = value.toLowerCase();
      return (
        fullName.includes(searchValue) ||
        client.hospital_name.includes(searchValue) ||
        client.mobile.includes(searchValue) ||
        client.email.toLowerCase().includes(searchValue) ||
        client.status.includes(searchValue)
      );
    });

    setFilteredData(filtered);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleEditClick = (id) => {
    setClientId(id);
    navigate('/edit-client');
  };

  const handleViewClick = (id) => {
    setClientId(id);
    navigate('/edit-client', { state: { type: 'view' } });
  };

  // Define columns for the table
  const columns = [
    {
      title: "Client Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      render: (text, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: "Hospital Name",
      dataIndex: "hospital_name",
      sorter: (a, b) => a.hospital_name.length - b.hospital_name.length
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
      render: (text, record) => (
        <Link to="#">{record.mobile}</Link>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, record) => (
        <StatusButton
          status={status}
          clientId={record.id}
          type="client"
          mobileNumber={record.mobile}
          isMobileVerified={record.is_mobile_verified}
          isEmailVerified={record.is_email_verified}
          userEmail={record.email}
        />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="text-end">
          <button onClick={() => handleEditClick(record.id)} className="btn btn-primary me-2">Edit</button>
          <button onClick={() => handleViewClick(record.id)} className="btn btn-secondary">View</button>

          {/* Other actions like Delete can be added here */}
        </div>
      ),
    }
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Clients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Client List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {error ? (
            <div className="main-wrapper error-wrapper">
              <div className="error-box">
                <img className="img-fluid" src={error02} alt="Logo" />
                <h3>
                  <img
                    className="img-fluid mb-0"
                    src={dangericon}
                    alt="Logo"
                  />{" "}
                  Service Unavailable
                </h3>
                <p>Sorry, an error has occured, and we're working to fix the problem.</p>
                <Link to="/" className="btn btn-primary go-home">
                  Back to Home
                </Link>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Client List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    className="form-control"
                                    placeholder="Search here"
                                  />
                                  <Link className="btn">
                                    <img src={searchnormal} alt="#" />
                                  </Link>
                                </form>
                              </div>
                              <div className="add-group">
                                <Link
                                  to="/add-client"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-primary doctor-refresh ms-2"
                                  onClick={fetchClients}
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          total: dataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={filteredData}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Notification-box -- TODO */}
      </div>
    </>
  );

};

export default ClientsList;
