import React, { useEffect, useState, useRef } from 'react';
import CardsGrid from './patient-info-card';
import { clinicService } from "../../../services/clinic/clinicService";
import { patientService } from "../../../services/patient/patientService";
import { ENDPOINT } from "../../../config/endpoint";
import { doctorService } from "../../../services/doctor/doctorService";
import Patient_Dashboard from "../Patient_Dashboard/Patient_Dashboard";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import getClinicId from '../../../services/getClinicId';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClinicalHealthCard = ({ user, authenticated, token, clinic_id }) => {
    const [showVitals, setShowVitals] = useState(false);
    const [patients, setPatients] = useState([]);
    const [processPatient, setProcessPatient] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const socketsRef = useRef({}); // Store WebSocket instances
    const lastStatusRef = useRef({}); // Store last statuses

    useEffect(() => {
        if (clinic_id) {
            lastStatusRef.current = {};
            getPatients();
            setErrorMessage(null);
        } else {
            setErrorMessage("No clinic is selected, please select a clinic to proceed further.");
        }

        return () => {
            // Close all WebSocket connections on cleanup
            Object.values(socketsRef.current).forEach((socket) => {
                socket.close();
            });
            socketsRef.current = {};
        };
    }, [clinic_id]);

    const getStatusMessage = (status) => {
        const statusMessages = {
            200: 'The proRITHM Device is Connected to the App',
            202: 'The Device is connected and testing but paused from the App',
            201: 'The Device has completed the test',
            203: 'The Device has connected to backend and started a test',
            204: 'The Device has stopped due to some error',
            401: 'Authentication Token has expired',
            410: 'The Device is disconnected from the App',
            422: 'The Device is not placed properly on the Patient',
            503: 'The App is not connected to the internet or there is a poor network connection',
        };
        return statusMessages[status] || null;
    };

    const openSocket = async (patient) => {
        const url = ENDPOINT.getPatientVitals.replace('{patient_id}', patient.patient_id) + '&token=' + token;

        // Close any existing socket for the patient
        if (socketsRef.current[patient.patient_id]) {
            socketsRef.current[patient.patient_id].close();
        }

        // Create a new WebSocket instance
        const socket = new WebSocket(url);

        socket.onopen = () => {
            console.log(`WebSocket connection opened for patient: ${patient.patient_id}`);
        };

        socket.onmessage = (event) => {
            const newData = JSON.parse(event.data);

            // Update patient data
            const updatedPatients = [...patients];
            const patientIndex = updatedPatients.findIndex(p => p.patient_id === patient.patient_id);
            if (patientIndex > -1) {
                if (newData?.b) updatedPatients[patientIndex].battery = newData.b;
                if (!updatedPatients[patientIndex].vitals) {
                    updatedPatients[patientIndex].vitals = { vitals: {}, ecg_clean: [] };
                }
                if (newData?.vitals) updatedPatients[patientIndex].vitals.vitals = newData.vitals;
                if (newData?.ecg_clean) updatedPatients[patientIndex].vitals.ecg_clean = newData.ecg_clean;
                if (newData?.duration) updatedPatients[patientIndex].duration = newData.duration;

                // Handle status messages
                if (newData?.status) {
                    const statusMessage = getStatusMessage(newData.status);
                    const patientId = patient.patient_id;

                    if (
                        statusMessage &&
                        lastStatusRef.current[patientId] !== newData.status
                    ) {
                        lastStatusRef.current[patientId] = newData.status;

                        const audio = new Audio('/sounds/alert_message.mp3');
                        audio.play().catch((error) => console.error("Audio play failed:", error));

                        toast.info(`${patient.first_name || "Patient"}: ${statusMessage}`, {
                            position: "top-right",
                            autoClose: 10000,
                        });
                    }
                }

                setPatients(updatedPatients);
                if (selectedPatient?.patient_id === patient.patient_id) {
                    setSelectedPatient(updatedPatients[patientIndex]);
                }
            }
        };

        socket.onclose = () => {
            console.log(`WebSocket connection closed for patient: ${patient.patient_id}`);
        };

        socketsRef.current[patient.patient_id] = socket;
    };

    const processPatients = async () => {
        for (let patient of patients) {
            await openSocket(patient);
        }
        setProcessPatient(false);
        setShowVitals(true);
    };

    useEffect(() => {
        if (processPatient) {
            processPatients();
        }
    }, [processPatient]);

    const getPatients = async () => {
        try {
            const assignedPatients = await clinicService.getAssignedPatients(clinic_id);
            setPatients(assignedPatients);
            setProcessPatient(true);
        } catch (error) {
            console.error("Error fetching assigned patients:", error);
        }
    };

    const selectPatient = (patient) => {
        setSelectedPatient(patient);
    };

    const unselectPatient = () => {
        setSelectedPatient(null);
    };

    return (
        <>
            {/* <ToastContainer /> */}
            <div className="page-wrapper">
                <div className="content">
                    {!clinic_id && errorMessage && (
                        <div className="d-inline-block">
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                    )}
                    {showVitals && !selectedPatient && (
                        <>
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/admin-dashboard">Dashboard</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="feather-chevron-right">
                                                    <FeatherIcon icon="chevron-right" />
                                                </i>
                                            </li>
                                            <li className="breadcrumb-item active">Remote Patient Monitoring</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                                <div style={{ width: '100%', height: '100%', padding: '0px' }}>
                                    <CardsGrid patientCards={patients} selectPatient={selectPatient} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {selectedPatient?.vitals?.vitals && (
                <Patient_Dashboard patient={selectedPatient} unselectPatient={unselectPatient} user={user} />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.userData,
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    clinic_id: state.auth.clinic_id
});

export default connect(mapStateToProps)(ClinicalHealthCard);
