// VitalCard.js
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define color ranges for vital parameters
const vitalRanges = {
    bp: {
        SBP: [
            { range: [0, 60], color: '#00d3c7' },
            { range: [80, 100], color: '#fef5e4' },
            { range: [120, 140], color: '#FFF2E5' },
            { range: [140, 160], color: '#ffe5e6' },
            { range: [160, Infinity], color: '#ffe5e6' },
        ],
        DBP: [
            { range: [0, 40], color: '#ffe5e6' },
            { range: [40, 60], color: '#fef5e4' },
            { range: [60, 80], color: '#FFF2E5' },
            { range: [90, 110], color: '#ffe5e6' },
            { range: [110, 130], color: '#ffe5e6' },
            { range: [130, Infinity], color: '#ffe5e6' },
        ],
        hr: [
            { range: [0, 19], color: '#ffe5e6' },    
            { range: [20, 39], color: '#FFF2E5' },  
            { range: [40, 59], color: '#fef5e4' },   
            { range: [101, 110], color: '#fef5e4' }, 
            { range: [111, 130], color: '#FFF2E5' }, 
            { range: [131, Infinity], color: '#ffe5e6' }, 
       
        ],
        rr: [
            { range: [0, 3], color: '#ffe5e6' },   
            { range: [4, 7], color: '#FFF2E5' }, 
            { range: [8, 11], color: '#fef5e4' }, 
            { range: [21, 25], color: '#fef5e4' }, 
            { range: [26, 30], color: '#FFF2E5' }, 
            { range: [31, Infinity], color: '#ffe5e6' }, 
        ],
        spo2: [
            { range: [0, 85], color: '#ffe5e6' },    
            { range: [85, 90], color: '#FFF2E5' }, 
            { range: [90, 94], color: '#fef5e4' },  
        ],
        skt: [
            { range: [0, 94.1], color: '#ffe5e6' },  
            { range: [94.1, 95.9], color: '#fef5e4' }, 
            { range: [99.5, Infinity], color: '#ffe5e6' },  
        ],
    },
};

const ProcessingDots = () => (
    <div style={{ display: 'inline-flex' }}>
        <div className="dot" style={dotStyle(0)}></div>
        <div className="dot" style={dotStyle(1)}></div>
        <div className="dot" style={dotStyle(2)}></div>
        <style>
            {`
                @keyframes blink {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0; }
                }
                .dot {
                    width: 6px;
                    height: 6px;
                    margin: 0 2px;
                    background-color: #000;
                    border-radius: 50%;
                    animation: blink 1.4s infinite;
                }
            `}
        </style>
    </div>
);

const dotStyle = (delayIndex) => ({
    animationDelay: `${0.2 * delayIndex}s`,
});


const VitalCard = ({ label, value, type, patientId, muteTimers }) => {
    const [alertedVitals, setAlertedVitals] = useState({});

    // Map vital types to their full names
    const vitalLabels = {
        SBP: "Systolic BP",
        DBP: "Diastolic BP",
        hr: "Heart Rate",
        rr: "Respiratory Rate",
        spo2: "Oxygen Saturation",
        skt: "Skin Temperature",
    };

    // Resolve the text label for the vital type
    const displayLabelText = vitalLabels[type] || "";

    const displayValue = value !== 'N/A' ? value : <ProcessingDots />;
    // Function to determine background color based on value and ranges
    const getBackgroundColor = (type, value) => {

        const ranges = vitalRanges.bp[type]; 
        if (!ranges) return '#f9fafb'; // Default color if no ranges are defined

        for (const { range, color } of ranges) {
            if (value >= range[0] && value < range[1]) {
                return color;
            }
        }
        return '#f9fafb'; // Default color if no match found
    };

    const backgroundColor = getBackgroundColor(type, value);

    useEffect(() => {
        const isMuted = muteTimers[patientId] && muteTimers[patientId] > Date.now();

        if (backgroundColor === '#ffe5e6') {
            setAlertedVitals((prev) => {
                if (!prev[type]) {
                    // Trigger toast notification for abnormal vitals
                    const alertMessage = `Vital Alert: ${displayLabelText} is abnormal for Patient ID: ${patientId}`;
                    toast.error(alertMessage, {
                        position: 'top-center',
                        autoClose: 20000,
                    });

                   //Play audio notification only if not muted
                    if (!isMuted) {
                        const audio = new Audio('/sounds/notification-alert.mp3');
                        audio.play().catch((error) => console.error('Audio play failed:', error));
                    }

                    return { ...prev, [type]: true }; // Mark this vital as alerted
                }
                return prev; // No state update if already alerted
            });
        } else {
            // Reset alert state for normal vitals
            setAlertedVitals((prev) => {
                if (prev[type]) {
                    return { ...prev, [type]: false };
                }
                return prev; // No state update if already not alerted
            });
        }
  
    }, [backgroundColor, displayLabelText, patientId, type, muteTimers]);


    return (
        <div
            style={{ background: backgroundColor, padding: '10px', borderRadius: '8px', textAlign: 'center', }}
        >
            <div style={{ fontSize: '14px', display: 'flex', gap: '8px', alignItems: 'center' }}>
                {label}
                {displayValue}
            </div>

        </div>
    );
};



export default VitalCard;
