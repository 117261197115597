import React, { useState } from 'react';
import { login02 } from '../imagepath';
import { requestOtp, updatePassword, verifyOtp, verifyOtpForMobileReset } from '../../services/userService';
import proRithmLogo from "../../assets/img/proRithm.png"
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { Eye, EyeOff } from 'react-feather';
import { FaEye, FaEyeSlash } from "react-icons/fa";


const ResetPassword = (props) => {
    const { logoutuser } = props;
    const location = useLocation();
    const [mobileNumber, setMobileNumber] = useState(location.state?.mobileNumber || ''); // Pre-fill with the mobile number
    const [otp, setOtp] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState(null);
    const [showOtp, setShowOtp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [token, setToken] = useState(null);

    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    // Separate states for toggling password visibility
    const [showPasswordVisible, setShowPasswordVisible] = useState(false);
    const [showConfirmPasswordVisible, setShowConfirmPasswordVisible] = useState(false);


    const navigate = useNavigate();

    const getOtp = async () => {
        if (mobileNumber && mobileNumber.length === 10) {
            console.log(mobileNumber);
            setError(null);
            const resp = await requestOtp(mobileNumber);
            if (resp) {
                setShowOtp(true);
                // store.dispatch({type: 'NOTIFY', data: resp.message});
            } else {
                setError('Please enter valid mobile number');
            }
        } else {
            setError('Please enter valid mobile number');
        }
    }

    const submitOtp = async () => {
        if (otp && otp.length === 6) {
            setError(null);
            const resp = await verifyOtpForMobileReset({ otp, username: mobileNumber });
            if (resp) {
                setShowOtp(false);
                setShowPassword(true);
                setToken(resp.token)
            } else {
                setError('Please enter valid otp');
            }
        } else {
            setError('Please enter valid otp');
        }
    }

    const submitPassword = async () => {
        if (password && confirmPassword && password === confirmPassword) {
            setError(null);
            const resp = await updatePassword(token, { new_password: password, confirm_password: confirmPassword });
            if (resp) {
                setModalMessage('Password has been successfully changed. Login now with your new password.');
                setShowModal(true);
                setShowPassword(false);
            } else {
                setModalMessage('Failed to update the password. Please try again.');
                setShowModal(true);
            }
        } else if (password && confirmPassword && password !== confirmPassword) {
            setError("Passwords don't match");
        } else {
            setError('Please enter a valid password');
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        if (modalMessage.includes('successfully')) {
            // Logout logic
            localStorage.removeItem('authToken'); // Clear token
            localStorage.removeItem('user'); // Clear user data if stored
            navigate('/login'); // Redirect to login page
        }
    };

    return (
        <div>
            <div className="main-wrapper login-body">
                <div className="container-fluid px-0">
                    <div className="row">
                        {/* Login logo */}
                        <div className="col-lg-6 login-wrap">
                            <div className="login-sec">
                                <div className="log-img">
                                    <img className="img-fluid" src={login02} alt="Logo" />
                                </div>
                            </div>
                        </div>
                        {/* /Login logo */}
                        {/* Login Content */}
                        <div className="col-lg-6 login-wrap-bg">
                            <div className="login-wrapper">
                                <div className="loginbox">
                                    <div className="login-right">
                                        <div className="login-right-wrap">
                                            <div className="account-logo">
                                                <Link to="/">
                                                    <img src={proRithmLogo} width={165} height={45} alt="#" />
                                                </Link>
                                            </div>
                                            <h2>Reset Password</h2>
                                            {/* Form */}
                                            {(!showOtp && !showPassword) && <>
                                                <form>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Mobile Number <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" maxLength={10} minLength={10} value={mobileNumber}
                                                            readOnly />
                                                        {error && <p className="login-danger">{error}</p>}
                                                    </div>
                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button" onClick={getOtp}>
                                                            Reset Password
                                                        </button>
                                                    </div>
                                                </form>
                                            </>}
                                            {(showOtp && !showPassword) && <>
                                                <form>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Enter OTP <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="number" value={otp}
                                                            onChange={(e) => setOtp(e.target.value)} />
                                                        {error && <p className="login-danger">{error}</p>}
                                                    </div>
                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button" onClick={submitOtp}>
                                                            Submit OTP
                                                        </button>
                                                    </div>
                                                </form>
                                            </>}
                                            {(!showOtp && showPassword) && (
                                                <form>
                                                    {/* New Password Field */}
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            New Password <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="password-input-wrapper">
                                                            <input
                                                                className="form-control"
                                                                type={showPasswordVisible ? 'text' : 'password'}
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            />
                                                            <span className="toggle-password" onClick={() => setShowPasswordVisible(!showPasswordVisible)}>
                                                                {showPasswordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* Confirm New Password Field */}
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Confirm New Password <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="password-input-wrapper">
                                                            <input
                                                                className="form-control"
                                                                type={showConfirmPasswordVisible ? 'text' : 'password'}
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                            />
                                                            <span className="toggle-password" onClick={() => setShowConfirmPasswordVisible(!showConfirmPasswordVisible)}>
                                                                {showConfirmPasswordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {error && <p style={{
                                                            marginTop: '15px',
                                                            color: 'red',
                                                            fontSize: '12px',
                                                            textAlign: 'center'
                                                        }}  className="login-danger">{error}</p>}
                                                    {/* Submit Button */}
                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button" onClick={submitPassword}>
                                                            Reset Password
                                                        </button>
                                                    </div>

                                                    {/* Internal CSS */}
                                                    <style jsx>{`
                                                .react-feather-custom {
                                                    width: 16px;  /* Adjust width */
                                                    height: 16px; /* Adjust height */
                                                }
                                               
                                              `}</style>
                                                </form>
                                            )}

                                            {/* /Form */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Login Content */}
                    </div>
                </div>
            </div>

            {/* Modal */}
            {/* <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        logoutuser(); // Dispatch logout action
                        handleModalClose(); // Clear token and navigate to login
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {showModal && (
                <div
                    className="modal fade show"
                    style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleModalClose}
                                    style={{ fontSize: '32px', border: 'none', background: 'none', color: '#000' }}
                                >
                                    &times;
                                </button>
                            </div>
                            <h3 style={{ marginTop: '20px', textAlign: 'center' }}>Reset Password</h3>
                            <div className="modal-body text-center alert alert-info" style={{ margin: '30px' }}>
                                {modalMessage}
                            </div>
                            <div className="modal-footer">
                                <Button variant="primary" onClick={() => {
                                    logoutuser(); // Dispatch logout action
                                    handleModalClose(); // Clear token and navigate to login    
                                }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>
    )
}

const mapDispatchToProps = dispatch => ({ logoutuser: () => dispatch({ type: 'LOGOUT' }) });
export default connect(null, mapDispatchToProps)(ResetPassword);
