import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Eye, EyeOff } from 'react-feather';
import proRithmLogo from "../../assets/img/proRithm.png";
import { rechangePassword } from '../../services/userService';
import 'bootstrap/dist/css/bootstrap.min.css';
import { login02 } from '../imagepath';
import { connect } from "react-redux";
import { Modal, Button } from 'react-bootstrap';

const ChangePassword = (props) => {
    const { user, authenticated, currentPath, logoutuser } = props;
    const location = useLocation();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false); // Modal visibility state

    // Separate state for each password visibility toggle
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const handleChangePassword = async () => {
        if (newPassword === confirmPassword) {
            setError('');
            const data = {
                old_password: oldPassword,
                new_password: newPassword,
            };

            try {
                const response = await rechangePassword('your-token-here', data); // Replace with actual token
                console.log(response); // Debugging: Log the API response

                if (response && response.status === 200) { // Check for the correct status
                    setModalMessage('Password has been successfully changed. Login now with your new password.');
                    setShowModal(true); // Show the modal upon success
                } else {
                    setError(response.message || 'You have entered incorrect old password.');
                }
            } catch (error) {
                console.error(error); // Debugging: Log any errors
                setError('An error occurred while changing the password. Please try again.');
                setModalMessage('Failed to change the password. Please check your details and try again.');
                setShowModal(true); // Show error modal
            }
        } else {
            setError("New password and confirm password don't match.");
        }
    };




    const handleModalClose = () => {
        setShowModal(false);
        if (modalMessage.includes('successfully')) {
            // Logout logic
            localStorage.removeItem('authToken'); // Clear token
            localStorage.removeItem('user'); // Clear user data if stored
            navigate('/login'); // Redirect to login page
        }
    };


    // Toggle password visibility for each field
    const toggleOldPasswordVisibility = () => {
        setOldPasswordVisible((prev) => !prev);
    };

    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible((prev) => !prev);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible((prev) => !prev);
    };

    return (
        <div>
            <div className="main-wrapper login-body">
                <div className="container-fluid px-0">
                    <div className="row">
                        {/* Login logo */}
                        <div className="col-lg-6 login-wrap">
                            <div className="log-img">
                                <img className="img-fluid" src={login02} alt="Logo" />
                            </div>
                        </div>
                        {/* Login Content */}
                        <div className="col-lg-6 login-wrap-bg">
                            <div className="login-wrapper">
                                <div className="loginbox">
                                    <div className="login-right">
                                        <div className="login-right-wrap">
                                            <div className="account-logo">
                                                <Link to="/">
                                                    <img src={proRithmLogo} width={165} height={45} alt="#" />
                                                </Link>
                                            </div>
                                            <h2>Change Password</h2>
                                            <form>
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Old Password <span className="login-danger">*</span>
                                                    </label>
                                                    <div className="password-input-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type={oldPasswordVisible ? 'text' : 'password'}
                                                            value={oldPassword}
                                                            onChange={(e) => setOldPassword(e.target.value)}
                                                        />
                                                        <span className="toggle-password" onClick={toggleOldPasswordVisibility}>
                                                            {oldPasswordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">
                                                        New Password <span className="login-danger">*</span>
                                                    </label>
                                                    <div className="password-input-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type={newPasswordVisible ? 'text' : 'password'}
                                                            value={newPassword}
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                        />
                                                        <span className="toggle-password" onClick={toggleNewPasswordVisibility}>
                                                            {newPasswordVisible ? <EyeOff className="react-feather-custom" style={{ width: '16px', height: '16px' }} /> : <Eye className="react-feather-custom" />}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Confirm New Password <span className="login-danger">*</span>
                                                    </label>
                                                    <div className="password-input-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type={confirmPasswordVisible ? 'text' : 'password'}
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                        />
                                                        <span className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
                                                            {confirmPasswordVisible ? <EyeOff className="react-feather-custom" style={{ width: '16px', height: '16px' }} /> : <Eye className="react-feather-custom" />}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Internal CSS */}
                                                <style jsx>{`
                                                .react-feather-custom {
                                                    width: 16px;  /* Adjust width */
                                                    height: 16px; /* Adjust height */
                                                }
                                               
                                              `}</style>

                                                {error && <p className="login-danger" 
                                                style={{
                                                    color: 'red',
                                                    fontSize: '12px',
                                                    textAlign: 'center'
                                                     }}>
                                                {error} 
                                                </p> }

                                                <div className="form-group login-btn">
                                                    <button className="btn btn-primary btn-block" type="button" onClick={handleChangePassword}>
                                                        Change Password
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Login Content */}
                    </div>
                </div>
            </div>


            {/* Modal */}
            {showModal && (
                <div
                    className="modal fade show"
                    style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleModalClose}
                                    style={{ fontSize: '32px', border: 'none', background: 'none', color: '#000' }}
                                >
                                    &times;
                                </button>
                            </div>
                            <h3 style={{ marginTop: '20px', textAlign: 'center' }}>Change Password</h3>
                            <div className="modal-body text-center alert alert-info" style={{ margin: '30px' }}>
                                {modalMessage}
                            </div>
                            <div className="modal-footer">
                                <Button variant="primary" onClick={() => {
                                    logoutuser(); // Dispatch logout action
                                    handleModalClose(); // Clear token and navigate to login    
                                }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({ logoutuser: () => dispatch({ type: 'LOGOUT' }) });
export default connect(null, mapDispatchToProps)(ChangePassword);
