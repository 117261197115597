import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
import {login02, loginicon01, loginicon02, loginicon03, loginlogo} from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {useState} from "react";
import {Eye, EyeOff} from "feather-icons-react/build/IconComponents";
import {login} from "../../../services/userService";
import {connect} from "react-redux";
import ErrorComponent from "../../error/error";
import proRithmLogo from "../../../../src/assets/img/proRithm.png"

const Login = ({user, onLogin, onLogout, authenticated}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleLogin = async () => {
        const res = await login({
            username,
            password
        });
        if (res) {
            onLogin(res);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent default form submission
        handleLogin();  // Manually call the login handler
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) {
            if (authenticated) {
                navigate('/');
            }
        }
    }, [authenticated]);

    // Event listener for Enter key globally
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleLogin();  // Submit the form when Enter is pressed
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [username, password]);  // Rerun if username or password changes

    return (
        <>
            {/* Main Wrapper */}
            <div className="main-wrapper login-body">
                <div className="container-fluid px-0">
                    <div className="row">
                        {/* Login logo */}
                        <div className="col-lg-6 login-wrap">
                            <div className="login-sec">
                                <div className="log-img">
                                    <img
                                        className="img-fluid"
                                        src={login02}
                                        alt="#"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* /Login logo */}
                        {/* Login Content */}
                        <div className="col-lg-6 login-wrap-bg">
                            <div className="login-wrapper">
                                <div className="loginbox">
                                    <div className="login-right">
                                        <div className="login-right-wrap">
                                            <div className="account-logo">
                                                {/* <Link to="deepfacts-dashboard">
                                                    <img src={proRithmLogo}  width={165} height={45}  alt="#"/>
                                                </Link> */}
                                                   <img src={proRithmLogo}  width={165} height={45}  alt="#"/>
                                            </div>
                                            <h2>Login</h2>
                                            {/* Form */}
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label>
                                                        Mobile Number <span className="login-danger">*</span>
                                                    </label>
                                                    <input className="form-control" type="text" minLength={10} maxLength={10} required value={username}
                                                        onChange={(event) => setUsername(event.target.value)}/>
                                                </div>
                                                <div className="form-group">
                                                    <label>
                                                        Password <span className="login-danger">*</span>
                                                    </label>
                                                    <input
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        className="form-control pass-input"
                                                        required
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <span
                                                        className="toggle-password"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {passwordVisible ? <EyeOff className="react-feather-custom"/> :
                                                            <Eye className="react-feather-custom"/>}
                                                    </span>
                                                </div>
                                                <div className="forgotpass">
                                                    <div className="remember-me">
                                                        <label
                                                            className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                                            {" "}
                                                            Remember me
                                                            <input type="checkbox" name="radio"/>
                                                            <span className="checkmark"/>
                                                        </label>
                                                    </div>
                                                    <Link to="/forgot-password">Forgot Password?</Link>
                                                </div>
                                                <div className="form-group login-btn">
                                                    <button
                                                        className="btn btn-primary btn-block"
                                                        type="submit">
                                                        Login
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Login Content */}
                    </div>
                </div>
            </div>
            <ErrorComponent/>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.userData,
    authenticated: state.auth.authenticated
});

const mapDispatchToProps = dispatch => ({
    onLogin: (data) => dispatch({type: 'LOGIN', data}),
    onLogout: () => dispatch({type: 'LOGOUT'})
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
