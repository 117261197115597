// src/services/getClinicId.js
import {store} from "../utils/redux/store";

const getClinicId = () => {
    const state = store.getState();
    const clinic_id = state.auth.clinic_id;
    const userRole = state.auth.role;

    if (!clinic_id) {
        return {
            clinic_id: null, 
            error: userRole === 'DeepFactsSuperAdmin' || userRole ==='ClientSuperAdmin' 
                ? 'Please select a clinic to proceed.' 
                : 'No Clinic is available. Ensure the Clinic is selected.',
        };
    }
    return { clinic_id, error: null };
};

export default getClinicId;
