import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const OxygenSaturationChart = ({ patient }) => {
    const chartRef = useRef(null);
    const windowSize = 50;
    const [chart, setChart] = React.useState(null);

    // Create the chart when initial data is available
    const createChart = () => {
        const ctx = chartRef.current.getContext('2d');
        let chartData = { labels: [], data: [] };

        if (patient.vitals?.vitals.spo2 && patient.vitals?.vitals.spo2.length > 0) {
            for (let i = 0; i < patient.vitals.vitals.spo2.length; i++) {
                chartData.labels.push(patient.vitals.vitals.spo2[i].t);
                chartData.data.push(patient.vitals.vitals.spo2[i].v);
            }
        }

        const spo2Chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: chartData.labels,
                datasets: [{
                    label: 'Oxygen Saturation',
                    data: chartData.data,
                    borderColor: '#FFE15D',
                    backgroundColor: 'rgba(255, 54, 103, 0.2)',
                    tension: 0.4,
                    pointRadius: 0,
                    fill: false,
                }],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: true,
                    },
                },
                animation: {
                    duration: 200,
                },
            },
        });

        setChart(spo2Chart);
    };

    // Update the chart data when vitals change
    const updateChart = () => {
        let chartData = { labels: [], data: [] };
        
        if (patient.vitals?.vitals.spo2 && patient.vitals.vitals.spo2.length > 0) {
            for (let i = (patient.vitals.vitals.spo2.length > windowSize ? patient.vitals.vitals.spo2.length - windowSize : 0); i < patient.vitals.vitals.spo2.length; i++) {
                chartData.labels.push(patient.vitals.vitals.spo2[i].t);
                chartData.data.push(patient.vitals.vitals.spo2[i].v);
            }
        }

        if (chart) {
            chart.data.labels = chartData.labels;
            chart.data.datasets[0].data = chartData.data;

            if (chart.data.labels.length >= windowSize) {
                chart.data.labels.splice(0, (chartData.labels.length - windowSize));
                chart.data.datasets[0].data.splice(0, (chartData.labels.length - windowSize));
            }
            chart.update();
        }
    };

    useEffect(() => {
        // Create the chart only if data is available or after the component mounts
        if (patient) {
            createChart();
        }

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, []);

    useEffect(() => {
        // Update the chart when the patient's vitals change
        if (chart && patient.vitals?.vitals.spo2) {
            updateChart();
        }
    }, [patient.vitals?.vitals.spo2]);

    return (
        <canvas
            id="oxygen-saturation"
            width={695}
            height={180}
            style={{
                display: 'block',
                boxSizing: 'border-box',
                height: '180px',
                width: '695px',
                marginTop: '20px',
            }}
            ref={chartRef}
        ></canvas>
    );
};

export default OxygenSaturationChart;
