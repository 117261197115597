import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { DatePicker } from "antd";
import deviceApi from '../../services/DeepFactsServices/DeviceRoutes';
import moment from 'moment';
import { Modal } from 'bootstrap';
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import DeleteIcon from "../../assets/img/delete.png";

const AddDevice = () => {
  const [popupMessage, setPopupMessage] = useState(''); // State for popup message
  const [isSuccessful, setIsSuccessful] = useState(false); // State to control the icon display
  const [formData, setFormData] = useState({
    device_model: '',
    device_version: '',
    device_mac_id: '',
    firmware_version: '',
    manufacturer_name: '',
    production_batch_number: '',
    manufacturing_date: '',
    status: 'Inventory',
  });

  const navigate = useNavigate(); // To navigate to the device list after closing the popup

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : '';
    setFormData({
      ...formData,
      manufacturing_date: formattedDate,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await deviceApi.createDevice(formData);

      if (response.status === 409) {
        setPopupMessage(response.message);
        setIsSuccessful(false); // Set to true for success case
      } 
      setPopupMessage(response.message);
      setIsSuccessful(true);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      console.error('Error creating device:', error);
      setPopupMessage('Failed to add device. Please try again.');
      setIsSuccessful(false);

      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/device-list'); // Navigate to device list if successful
    }
  };

  return (
    <div>
      {/*<Header />
      <Sidebar id="menu-item6" id1="menu-items6" activeClassName="add-device" />*/}
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Devices </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Device</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Device Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Device Model <span className="login-danger">*</span>
                            </label>
                            <input
                              name="device_model"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.device_model}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Device Version <span className="login-danger">*</span>
                            </label>
                            <input
                              name="device_version"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.device_version}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Device MacID <span className="login-danger">*</span>
                            </label>
                            <input
                              name="device_mac_id"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.device_mac_id}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Firmware Version <span className="login-danger">*</span>
                            </label>
                            <input
                              name="firmware_version"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.firmware_version}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Manufacturer Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="manufacturer_name"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.manufacturer_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Manufacturing Date <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              value={formData.manufacturing_date ? moment(formData.manufacturing_date) : null}
                              onChange={handleDateChange}
                              format="YYYY-MM-DD"
                              style={{
                                borderColor: formData.manufacturing_date ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Production Batch Number <span className="login-danger">*</span>
                            </label>
                            <input
                              name="production_batch_number"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.production_batch_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Inventory"
                                  className="form-check-input"
                                  checked={formData.status === 'Inventory'}
                                  onChange={handleChange}
                                />
                                Inventory
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Malfunctioned"
                                  className="form-check-input"
                                  checked={formData.status === 'Malfunctioned'}
                                  onChange={handleChange}
                                />
                                Malfunctioned
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Active"
                                  className="form-check-input"
                                  checked={formData.status === 'Active'}
                                  onChange={handleChange}
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Inactive"
                                  className="form-check-input"
                                  checked={formData.status === 'Inactive'}
                                  onChange={handleChange}
                                />
                                Inactive
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3>
                <div className="m-t-20">
                  {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}
      </>
    </div>
  );
};

export default AddDevice;
