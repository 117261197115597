import {invokeApi} from "../../utils/httpService";
import {ENDPOINT} from "../../config/endpoint";

const sampleAssignedPatients = [
    {
        "id": 1,
        "clinic_id": 1,
        "doctor_id": 1,
        "block": "1",
        "ward": "1",
        "bed": "1",
        "created_time": "2024-08-21T11:28:49",
        "patient_id": 1,
        "device_mac_id": "00:1A:2B:3C:4D:5E",
        "floor": "1",
        "room": "1",
        "status": "Assigned",
        "updated_time": "2024-08-21T11:28:49"
    }
];

export const clinicService = {
    getAssignedPatients: async (clinicId) => {
        try {
            const url = ENDPOINT.getAssignedPatients.replace('{clinic_id}', clinicId);
            const response = await invokeApi(url, 'GET');
            return response.assign_patients;
        } catch (error) {
            // console.error('Error fetching patients:', error);
            return false;
        }
    }
}