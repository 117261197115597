import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';

const TemperatureChart = ({ patient }) => {
  const chartRef = useRef(null);
  const windowSize = 12; // Show max 14 bars
  const [chart, setChart] = useState(null);

  const createChart = (chartData) => {
    const options = {
      chart: {
        height: 180,
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '90%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 6,
        colors: ['transparent'],
      },
      series: [
        {
          name: 'Temperature',
          color: '#2E37A4',
          data: chartData.data,
        },
      ],
      xaxis: {
        categories: chartData.labels,
        labels: {
          show: false, // Hide x-axis labels
        },
      },
      yaxis: {
        max: 100,
        tickAmount: 5,
      },
    };

    const temperatureChart = new ApexCharts(chartRef.current, options);
    temperatureChart.render();
    setChart(temperatureChart);
  };

  const updateChart = () => {
    if (chart) {
      let chartData = { labels: [], data: [], colors: [] };

      // Extract temperature data from patient vitals
      if (patient.vitals?.vitals.skt && patient.vitals.vitals.skt.length > 0) {
        // Get the last 14 values
        const recentData = patient.vitals.vitals.skt.slice(
          Math.max(0, patient.vitals.vitals.skt.length - windowSize)
        );
        recentData.forEach((entry) => {
          chartData.labels.push(entry.t);
          chartData.data.push(entry.v);

          // Conditionally change color based on temperature value
          if (entry.v < 97) {
            chartData.colors.push('#DCDDEF'); // Color for temperatures less than 97
          } else {
            chartData.colors.push('#2E37A4'); // Default color
          }
        });
      }

      // Update the chart with new data and colors
      chart.updateOptions({
        xaxis: {
          categories: chartData.labels,
          labels: {
            show: false, // Hide x-axis labels when updating the chart
          },
        },
        series: [
          {
            name: 'Temperature',
            data: chartData.data,
          },
        ],
        plotOptions: {
          bar: {
            colors: {
              ranges: chartData.colors.map((color) => ({
                from: 0,
                to: 100,
                color: color,
              })),
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (chartRef.current && patient) {
      // Initialize the chart with initial data
      let initialData = { labels: [], data: [], colors: [] };

      if (patient.vitals?.vitals.skt && patient.vitals.vitals.skt.length > 0) {
        const recentData = patient.vitals.vitals.skt.slice(
          Math.max(0, patient.vitals.vitals.skt.length - windowSize)
        );
        recentData.forEach((entry) => {
          initialData.labels.push(entry.t);
          initialData.data.push(entry.v);

          // Conditionally change color based on temperature value
          if (entry.v < 97) {
            initialData.colors.push('#DCDDEF'); // Color for temperatures less than 97
          } else {
            initialData.colors.push('#2E37A4'); // Default color
          }
        });
      }

      createChart(initialData);
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [patient]);

  useEffect(() => {
    // Update the chart when patient data changes
    updateChart();
  }, [patient.vitals?.vitals.skt]);

  return (
    <div
      id="temperature-chart"
      style={{ display: 'block', boxSizing: 'border-box', height: '110px' }}
      className="chartContainer"
      ref={chartRef}
    ></div>
  );
};

export default TemperatureChart;
