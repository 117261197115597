/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table, ConfigProvider } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination';
import { plusicon, refreshicon, searchnormal, error02, dangericon } from '../imagepath';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import patientApi from '../../services/ClientServices/PatientRoutes';
import StatusButton from '../../statusbutton';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { store } from "../../../src/utils/redux/store";
import { usePatient } from '../../context/PatientContext';
import { connect } from "react-redux";

const PatientList = ({ clinic_id }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const location = useLocation();
  const { setPatientId } = usePatient();
  const auth = store.getState().auth; // Access the auth state from Redux store
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchPatients = async () => {
    try {
      const data = await patientApi.getPatients();
      setPatients(data.patients || []);
      setFilteredData(data.patients || []);
      setError(null);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setError("500 Error");
      } else {
        setError("An error occurred while fetching patients.");
      }
      setDataSource([]);
    }
  };

  useEffect(() => {
    if (clinic_id) {
      fetchPatients();
    } else {
      setError("No clinic is selected, please select a clinic to proceed further.");
    }
  }, [clinic_id]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    const filtered = patients.filter((patient) => {
      const fullName = `${patient.first_name || ''} ${patient.last_name || ''}`.toLowerCase();
      const searchValue = value.toLowerCase();

      return (
        fullName.includes(searchValue) ||
        (patient.mobile || '').includes(searchValue) ||
        (patient.email?.toLowerCase() || '').includes(searchValue) ||
        (patient.doctor_name?.toLowerCase() || '').includes(searchValue) ||
        (patient.gender?.toLowerCase() || '').includes(searchValue) ||
        String(patient.age || '').includes(searchValue)
      );
    });

    setFilteredData(filtered);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleEditClick = (id) => {
    setPatientId(id)
    navigate('/edit-patient', { state: { id } });
  };

  const handleViewClick = (id) => {
    setPatientId(id)
    navigate('/edit-patient', { state: { id, type: 'view' } });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "first_name",
      render: (text, record) => `${record.first_name} ${record.last_name}`,
      sorter: (a, b) => a.first_name.localeCompare(b.first_name)
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
      render: (text, record) => (
        <Link to="#">{record.mobile}</Link>
      )
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.length - b.doctor_name.length
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age.length - b.age.length
    },


    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, record) =>
        <StatusButton status={status}
          mobileNumber={record.mobile}
          isMobileVerified={record.is_mobile_verified}
          isEmailVerified={record.is_email_verified}
          userEmail={record.email} />,
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="text-end">
          <button onClick={() => handleEditClick(record.id)} className="btn btn-primary me-2">Edit</button>
          <button onClick={() => handleViewClick(record.id)} className="btn btn-secondary">View</button>
        </div>
      ),
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Patients</a>
                </li>
                <li className="breadcrumb-item">
                  <i className="feather-chevron-right">
                    <FeatherIcon icon="chevron-right" />
                  </i>
                </li>
                <li className="breadcrumb-item active">Patient List</li>
              </ul>
            </div>
          </div>
        </div>
        {error === "500 Error" ? (
            <div className="main-wrapper error-wrapper">
              <div className="error-box">
                <img className="img-fluid" src={error02} alt="Service Unavailable" />
                <h3>
                  <img className="img-fluid mb-0" src={dangericon} alt="Danger Icon" />{" "}
                  Service Unavailable
                </h3>
                <p>Sorry, an error has occurred, and we're working to fix the problem.</p>
                <Link to="/" className="btn btn-primary go-home">
                  Back to Home
                </Link>
              </div>
            </div>
          ) : error === "No clinic is selected, please select a clinic to proceed further." ? (
            <div className="d-inline-block" style={{ textAlign: 'center' }}>
              <div
                className="alert alert-danger"
                role="alert"
                style={{ display: error ? 'block' : 'none', marginBottom: '10px' }}
              >
                {error}
              </div>
            </div>
          ) : (
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Patient List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  value={searchQuery}
                                  onChange={handleSearch}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/add-patient"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                                onClick={fetchPatients}
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive doctor-list">
                    <ConfigProvider theme={{
                      components: {
                        Table: {
                          headerBg: 'red'
                        },
                      },
                    }}>
                      <Table
                        pagination={{
                          total: patients.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={filteredData}
                        rowSelection={{
                          selectedRowKeys,
                          onChange: onSelectChange
                        }}
                        rowKey={(record) => record.id}
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Modal for deletion (if needed) */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic_id: state.auth.clinic_id
});

export default connect(mapStateToProps)(PatientList);
