/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import HeartRateChart from "./HeartRate";
import RespiratoryRateChart from "./RespiratorRate";
import OxygenSaturationChart from "./OxygenSaturation";
import TemperatureChart from "./TemperatureChart";
import BloodPressureChart from "./BloodPressure";
//import HealthChart from "./HealthChart";
import SleepChart from "./SleepChart";
//import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import 'chartjs-adapter-date-fns';
import { PiDropBold } from "react-icons/pi";
import { BsLungs } from "react-icons/bs";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  health_img,
  morning_img_03,
  report_icon1,
  report_icon2,
  report_icon3,
  report_icon4,
  blogimg4,
  blogimg12,
  blogimg8,
  profiles03,
  imagesend
} from "../../imagepath";

import "react-calendar/dist/Calendar.css";
import MarketAreaChart from "./HealthChart";
import Select from "react-select";
import Calendar from "react-calendar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RadialBarChart from "./FullChart";
import "./patient_dashboard.css";


const Patient_Dashboard = ({ patient, unselectPatient, user }) => {
  let currentRecord = 0;
  const updateCurrentRecord = (newRecord) => {
    currentRecord = newRecord;
  };
  return (
    <>
      <div className="page-wrapper" style={{ marginBottom: '0px', padding: '0px' }}>
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item" >
                    <a onClick={() => {
                      unselectPatient();
                    }} style={{ cursor: 'pointer' }}>RPM Dashboard</a>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">
                    Patient Dashboard
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="good-morning-blk">
            <div className="row">
              <div className="col-md-6">
                <div className="morning-user">
                  <h2>
                    Welcome back, <span>{user.role === 'DeepFactsSuperAdmin' ? "Vamsi Karatam" : user?.name}!</span>
                  </h2>
                  <p>Have a nice day at work</p>
                </div>
              </div>
              <div className="col-md-6 position-blk">
                <div className="morning-img">
                  <img src={morning_img_03} alt="#" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Heart Rate card - start */}
            <div className="col-12 col-md-12 col-lg-12 col-xl-4">
              <div className="card report-blk">
                <div className="card-body">
                  <div className="report-head">
                    <div className="vital-card">
                      <h4>
                        <img
                          src={report_icon1}
                          className="me-2"
                          alt="#"
                        />
                        Heart Rate
                      </h4>
                      <h5>
                        <span className="zoom-in-out-box">
                          {patient?.vitals?.vitals?.hr?.length > 0
                            ? patient.vitals.vitals.hr[patient.vitals.vitals.hr.length - 1]?.v || 'N/A'
                            : 'N/A'}
                        </span>

                      </h5>
                    </div>
                  </div>
                  <div id="heart-rate" />
                  <HeartRateChart patient={patient} currentRecord={updateCurrentRecord} />
                </div>
              </div>
            </div>
            {/* Heart Rate card - end */}

            {/* Respiratory Rate card - start */}
            <div className="col-12 col-md-12 col-lg-12 col-xl-4">
              <div className="card report-blk">
                <div className="card-body">
                  <div className="report-head">
                    <div className="vital-card">
                      <h4>
                        <BsLungs className="vital-icons" />
                        Respiratory Rate
                      </h4>
                      <h5>
                        <span className="zoom-in-out-box">
                          {patient?.vitals?.vitals?.rr?.length > 0
                            ? patient.vitals.vitals.rr[patient.vitals.vitals.rr.length - 1]?.v || 'N/A'
                            : 'N/A'}
                        </span>

                      </h5>
                    </div>
                  </div>
                  <div id="respiratory-rate" />
                  <RespiratoryRateChart patient={patient} />
                </div>
              </div>
            </div>
            {/* Respiratory Rate card - start */}

            {/* oxygen saturation card - start */}
            <div className="col-12 col-md-12 col-lg-12 col-xl-4">
              <div className="card report-blk">
                <div className="card-body">
                  <div className="report-head">
                    <div className="vital-card">
                      <h4>
                        <PiDropBold className="vital-icons" />
                        Oxygen Saturation
                      </h4>
                      <h5>
                        <span className="zoom-in-out-box">
                          {patient?.vitals?.vitals?.spo2?.length > 0
                            ? patient.vitals.vitals.spo2[patient.vitals.vitals.spo2.length - 1]?.v || 'N/A'
                            : 'N/A'}
                        </span>

                      </h5>
                    </div>
                  </div>
                  <div id="oxygen-saturation" />
                  <OxygenSaturationChart patient={patient} />
                </div>
              </div>
            </div>
            {/* oxygen saturation card - end */}
          </div>

          <div className="row">
            {/* bp card - start */}
            <div className="col-12 col-md-12 col-lg-12 col-xl-8">
              <div className="card report-blk">
                <div className="card-body">
                  <div className="report-head">
                    <div className="vital-card">
                      <h4>
                        <img
                          src={report_icon3}
                          className="me-2"
                          alt="#"
                        />
                        Blood Pressure
                      </h4>
                      <h5>
                        <span className="zoom-in-out-box">
                          {(patient?.vitals?.vitals?.bp && patient?.vitals?.vitals?.bp.length > 0)
                            ?
                            patient?.vitals?.vitals?.bp[patient?.vitals?.vitals?.bp.length - 1]?.sp : 'N/A'}
                          /
                          {(patient?.vitals?.vitals?.bp && patient?.vitals?.vitals?.bp.length > 0) ? patient?.vitals?.vitals?.bp[patient?.vitals?.vitals?.bp.length - 1]?.dp : 'N/A'}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <BloodPressureChart patient={patient} />
                </div>
              </div>
            </div>
            {/* bp card - end */}
            <div className="col-12 col-md-2 col-xl-4 d-flex">
              <div className="card report-blk">
                <div className="card-body">
                  <div className="report-head">
                    <div className="vital-card">
                      <h4>
                        <img
                          src={report_icon2}
                          className="me-2"
                          alt="#"
                        />
                        Skin Temperature
                      </h4>
                      <h5>
                        <span className="zoom-in-out-box">
                          {patient?.vitals?.vitals?.skt?.length > 0
                            ? patient.vitals.vitals.skt[patient.vitals.vitals.skt.length - 1]?.v || 'N/A'
                            : 'N/A'}
                        </span>

                      </h5>
                    </div>
                  </div>
                  <div id="temperature-chart" />
                  <TemperatureChart patient={patient} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Patient_Dashboard;
