import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
//Client
import AddClient from "./components/client/AddClient";
import ClientsList from "./components/client/ClientList";
import EditClient from "./components/client/EditClient";
import ClientProfile from "./components/client/ClientProfile";
//Clinic
import AddClinic from "./components/clinic/AddClinic";
import ClinicsList from "./components/clinic/ClinicList";
import EditClinic from "./components/clinic/EditClinic";
import ClinicProfile from "./components/clinic/ClinicProfile";
//Doctor
import DoctorList from "./components/doctor/DoctorList";
import AddDoctor from "./components/doctor/AddDoctor";
import EditDoctor from "./components/doctor/EditDoctor";
import DoctorProfile from "./components/doctor/DoctorProfile";
//Nurse
import AddNurse from "./components/Nurse/AddNurse";
import NurseList from "./components/Nurse/NurseList";
import EditNurse from "./components/Nurse/EditNurse";
import NurseProfile from "./components/Nurse/NurseProfile";
//Patients
import PatientsList from "./components/patients/PatientsList";
import AddPatients from "./components/patients/AddPatients";
import EditPatients from "./components/patients/EditPatients";
import PatientsProfile from "./components/patients/PatientsProfile";
/* Devices */
import AddDevice from "./components/device/AddDevice";
import DeviceList from "./components/device/DeviceList";
import EditDevice from "./components/device/EditDevice";
//Remote Patient Monitoring
import RpmDetails from "./components/patients/RpmDetails";
import AssignPatient from "./components/patients/AssignPatient";
/* Pages */
import Error from "./components/pages/login/Error";
import ServerError from "./components/pages/login/ServerError";
import Profile from "./components/pages/login/Profile";
import BlankPage from "./components/pages/login/BlankPage";
/* Dashboard */
import DeepFacts_Dashboard from "./components/Dashboard/DeepFacts_Dashboard/Deepfacts_Dashboard";
import Client_Dashboard from "./components/Dashboard/Client_Dashboard/Client_Dashboard";
import Clinic_Dashboard from "./components/Dashboard/Clinic_Dashboard/Clinic_Dashboard";
import Doctor_Dashboard from "./components/Dashboard/Doctor_Dashboard/Doctor_Dashboard";
import RpmDashboard from "./components/Dashboard/Rpm_Dashboard/RpmDashboard";
import Patient_Dashboard from "./components/Dashboard/Patient_Dashboard/Patient_Dashboard";
/* Testing */
// import RpmDashboardTesting from "./components/Dashboard/testing_dashboard/RpmDashboard";
/* Layout */
import Layout from "./components/layout/Layout";
// status button
import StatusButton from "./statusbutton";
import Login from "./components/pages/login";
import VerifyOtp from "./VerifyOtp";
import VerifyEmailSuccess from "./VerifyEmail";
//password reset 
import ResetPassword from "./components/pages/resetpassword";
import ChangePassword from "./components/pages/changepassword";

const AppRouter = ({ user, authenticated }) => {

    useEffect(() => {
        if (authenticated) {

        }
    }, [authenticated, user]);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    {authenticated && (
                        <>
                            <Route path="/change-password" element={<ChangePassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/verify-otp" element={<VerifyOtp />} />
                            <Route path="/verify_email" element={<VerifyEmailSuccess />} />
                            <Route path={"/"} element={<Layout />}>
                                <Route path="/login" element={<Layout />} />
                                <Route path="/profile" element={<Profile />} />
                                {/* Client */}
                                <Route path="/add-client" element={<AddClient />} />
                                <Route path="/client-list" element={<ClientsList />} />
                                <Route path="/edit-client" element={<EditClient />} />
                                <Route path="/client-profile" element={<ClientProfile />} />
                                {/* Clinic */}
                                <Route path="/add-clinic" element={<AddClinic />} />
                                <Route path="/clinic-list" element={<ClinicsList />} />
                                <Route path="/edit-clinic" element={<EditClinic />} />
                                <Route path="/clinic-profile" element={<ClinicProfile />} />
                                {/* Doctor  */}
                                <Route path="/doctor-list" element={<DoctorList />} />
                                <Route path="/add-doctor" element={<AddDoctor />} />
                                <Route path="/editdoctor" element={<EditDoctor />} />
                                <Route path="/doctorprofile" element={<DoctorProfile />} />
                                {/* Nurse */}
                                <Route path="/nurse-list" element={<NurseList />} />
                                <Route path="/add-nurse" element={<AddNurse />} />
                                <Route path="/edit-nurse" element={<EditNurse />} />
                                <Route path="/nurse-profile" element={<NurseProfile />} />
                                {/* Patients */}
                                <Route path="/add-patient" element={<AddPatients />} />
                                <Route path="/patient-list" element={<PatientsList />} />
                                <Route path="/edit-patient" element={<EditPatients />} />
                                <Route path="/patient-profile" element={<PatientsProfile />} />
                                {/* Devices */}
                                <Route path="/add-device" element={<AddDevice />} />
                                <Route path="/device-list" element={<DeviceList />} />
                                <Route path="/edit-device" element={<EditDevice />} />
                                {/* Remote Patient Monitoring */}
                                <Route path="/assign-patient-list" element={<RpmDetails />} />
                                <Route path="/assign-patient" element={<AssignPatient />} />
                                {/* Dashboard */}
                                <Route path="/deepfacts-dashboard" element={<DeepFacts_Dashboard />} />
                                <Route path="/admin-dashboard" element={<RpmDashboard />} />
                                {/* <Route path="/testing-dashboard" element={<RpmDashboardTesting />} /> */}
                                <Route path="/clinic-dashboard" element={<Clinic_Dashboard />} />
                                <Route path="/client-dashboard" element={<Client_Dashboard />} />
                                <Route path="/doctor-dashboard" element={<Doctor_Dashboard />} />
                                <Route path="/patient-dashboard" element={<Patient_Dashboard />} />
                                {/* status button */}
                                <Route path="/status-button" element={<StatusButton status="Inactive" />} />
                                <Route path="/error" element={<Error />} />
                                <Route path="/server-error" element={<ServerError />} />
                                <Route path="/blankpage" element={<BlankPage />} />
                                <Route path="*" element={<Error />} />
                            </Route>
                        </>
                    )}
                </Routes>
            </BrowserRouter>
            <div className="sidebar-overlay"></div>
        </>
    );
};

export default AppRouter;