/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
// import "../../src/assets/js/app";
// import { baricon1, imguser, logo, noteicon, noteicon1, searchnormal, settingicon01, user06 } from './imagepath';
import Select from 'react-select';
import { logo, baricon, baricon1, searchnormal, imguser, noteicon, user06, settingicon01, noteicon1, } from "../imagepath";
import { connect } from "react-redux";
import proRithmLogo from "../../assets/img/proRithm.png";
import clinicApi from "../../services/ClientServices/ClinicRoutes";
import clientApi from "../../services/ClientServices/ClientRoutes";
import userprofile from "../../assets/img/user_profile.png"

const Header = ({ user, authenticated, logout, setClinicId, setClientId }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);

  // Fetch all clients if the role is DeepFactsSuperAdmin
  useEffect(() => {
    if (user.role === 'DeepFactsSuperAdmin') {
      clientApi.getClients()
        .then((clientsData) => {
          setClients(clientsData.clients);  // Assuming response has a 'clients' array
        })
        .catch((error) => {
          console.error('Error fetching clients:', error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (user.role === 'ClientSuperAdmin') {
      // Fetch clinics for ClientSuperAdmin
      clinicApi.getClinics(user.client_id)
        .then((clinicsData) => {
          setClinics(clinicsData.clinics); // Assuming clinicsData has a clinics array
        })
        .catch((error) => {
          console.error('Error fetching clinics:', error);
        });
    }
  }, [user]);

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    setClinics([]);  // Reset clinics when the client changes
    setSelectedClinic(null);

    setClientId(clientId);  // Store selected client_id in Redux

    // Fetch clinics based on the selected client
    clinicApi.getClinics(clientId)
      .then((clinicsData) => {
        if (clinicsData.status === 404) {
          setClinics([]);  // Clear clinics array
        } else {
          setClinics(clinicsData.clinics);  // Set the fetched clinics
        }
      })
      .catch((error) => {
        console.error("Error fetching clinics for client:", error);
      });
  };


  //Hanlde clinic change
  const handleClinicChange = (event) => {
    const selectedClinicId = event.target.value;
    setSelectedClinic(selectedClinicId);
    setClinicId(selectedClinicId); // Store selected clinic_id in Redux
  };

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
    document.getElementsByTagName("html")[0].classList.toggle('menu-opened');
    document.getElementsByClassName("sidebar-overlay")[0].classList.toggle("opened");
  };

  const openDrawer = () => {
    const div = document.querySelector(".main-wrapper");
    if (div?.className?.includes("open-msg-box")) {
      div?.classList?.remove("open-msg-box");
    } else {
      div?.classList?.add("open-msg-box");
    }
  };

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    // maximizeBtn.addEventListener('click', handleClick);

    return () => {
      // maximizeBtn.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="main-wrapper">
      <div className="header">
        <div className="header-left">
          <Link to="/" className="logo">
            <img src={proRithmLogo} width={165} height={45} style={{ marginLeft: '20px' }} alt="" />{" "}

          </Link>
        </div>

        <Link id="toggle_btn" to="#" onClick={handlesidebar}>
          <img src={baricon} alt="" />
        </Link>
        <Link id="mobile_btn" className="mobile_btn float-start" to="#" onClick={handlesidebarmobilemenu}>
          <img src={baricon1} alt="" />
        </Link>

        {user.role === 'DeepFactsSuperAdmin' && (
          <div className="top-nav-search mob-view">
            <form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '500px' }}>
              {/* Client Dropdown */}
              <select
                className="form-control"
                value={selectedClient || ""}
                onChange={handleClientChange}
                style={dropdownStyle}
              >
                <option value="">Select Client</option>
                {clients.map((client, index) => (
                  <option key={`client-${client.id}-${index}`} value={client.id}>
                    {client.hospital_name}
                  </option>
                ))}

              </select>

              {/* Clinic Dropdown */}
              <select
                className="form-control"
                value={selectedClinic || ""}
                onChange={handleClinicChange}
                style={dropdownStyle}
                disabled={!selectedClient || clinics.length === 0}
              >
                <option value="">{clinics.length === 0 ? 'No clinic available' : 'Select Clinic'}</option>
                {clinics.map((clinic, index) => (
                  <option key={`clinic-${clinic.id}-${index}`} value={clinic.id}>
                    {clinic.name}
                  </option>
                ))}
              </select>
            </form>
          </div>
        )}

        {user.role === 'ClientSuperAdmin' && (
          <div className="top-nav-search mob-view">
            <form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <select
                className="form-control"
                value={selectedClinic}
                onChange={handleClinicChange}
                style={{
                  textAlign: 'center',
                  textAlignLast: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: '0px',
                  appearance: 'none',
                  backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%232e37a4%27 stroke=%27%232e37a4%27 viewBox=%270 0 24 24%27 width=%27205px%27 height=%27205px%27%3E%3Cg%3E%3Cpath d=%27M7 10l5 5 5-5%27%3E%3C/path%3E%3C/g%3E%3C/svg%3E")', // Custom arrow
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 10px center',
                  backgroundSize: '20px',
                  paddingRight: '15px',
                }}
              >
                <option value="">Select Clinic</option>
                {clinics.map((clinic) => (
                  <option key={clinic.id} value={clinic.id}>
                    {clinic.name}
                  </option>
                ))}
              </select>
            </form>
          </div>
        )}

        <ul className="nav user-menu float-end">
          <li className="nav-item dropdown has-arrow user-profile-list">
            <Link
              to="#"
              className="dropdown-toggle nav-link user-link"
              data-bs-toggle="dropdown"
            >
              <div className="user-names">
                <h5>{user.username}</h5>
                <span>{user.role === 'DeepFactsSuperAdmin' ? "DeepFacts Admin" : user?.name}</span>
              </div>
              <span className="user-img">
                <img src={userprofile} alt="Admin" />
              </span>
            </Link>
            <div className="dropdown-menu">
              {user.role !== 'DeepFactsSuperAdmin' && (
                <Link className="dropdown-item" to="/profile">
                  My Profile
                </Link>
              )}
              {/* <Link className="dropdown-item" to="/reset-password">
                Reset Password
              </Link> */}
              <Link
                className="dropdown-item"
                to={{
                  pathname: "/reset-password",
                }}
                state={{ mobileNumber: user.username }}
              >
                Reset Password
              </Link>

              <Link className="dropdown-item" to="/change-password">
                Change Password
              </Link>
              <a style={{ cursor: "pointer" }} className="dropdown-item" onClick={logout}>
                Logout
              </a>
            </div>
          </li>
        </ul>
        <div className="dropdown mobile-user-menu float-end">
          <Link
            to="#"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical" />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <Link className="dropdown-item" to="/profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="edit-profile.html">
              Edit Profile
            </Link>
            <Link className="dropdown-item" to="/settings">
              Settings
            </Link>
            <Link className="dropdown-item" to="/login">
              Logout
            </Link>
          </div>
        </div>
      </div>

      {/* Notifications */}

    </div>
  );
};

const dropdownStyle = {
  textAlign: 'center',
  textAlignLast: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '0px',
  appearance: 'none',
  backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%232e37a4%27 stroke=%27%232e37a4%27 viewBox=%270 0 24 24%27 width=%27205px%27 height=%27205px%27%3E%3Cg%3E%3Cpath d=%27M7 10l5 5 5-5%27%3E%3C/path%3E%3C/g%3E%3C/svg%3E")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 10px center',
  backgroundSize: '20px',
  paddingRight: '15px',
  marginRight: '10px'
};

// const mapDispatchToProps = dispatch => ({ logout: () => dispatch({ type: 'LOGOUT' }) });
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: "LOGOUT" }),
  setClinicId: (clinicId) => dispatch({ type: "SET_CLINIC_ID", payload: clinicId }),
  setClientId: (clientId) => dispatch({ type: "SET_CLIENT_ID", payload: clientId }),  // Add this for client_id
});

export default connect(null, mapDispatchToProps)(Header);

