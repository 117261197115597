import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const HeartRateChart = ({ patient, currentRecord }) => {
  const ecgChartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [windowSize] = useState(200);

  let plottedIndex = useRef(0);

  const options = {
    animation: {
      duration: 0, // Disable animation for smoother experience
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: true,
      },
    },
  };

  const createChart = () => {
    if (chart) {
      chart.destroy(); // Destroy any existing chart before creating a new one
    }

    const ctx = ecgChartRef.current.getContext('2d');
    const newChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Heart Rate',
            data: [],
            borderColor: '#FF3667',
            tension: 0.4,
            pointRadius: 0,
            fill: false,
          },
        ],
      },
      options,
    });
    setChart(newChart);
  };

  const updateChart = () => {
    if (chart && patient?.vitals?.ecg_clean?.length > 0) {
      const newData = patient.vitals.ecg_clean[plottedIndex.current];

      if (newData) {
        chart.data.labels.push(newData.t);
        chart.data.datasets[0].data.push(newData.e);

        if (chart.data.datasets[0].data.length > windowSize) {
          chart.data.labels.shift();
          chart.data.datasets[0].data.shift();
        }

        chart.update();
        plottedIndex.current++;
        currentRecord(plottedIndex.current);
      }
    } else {
      console.log('No ECG data available yet.');
    }
  };

  useEffect(() => {
    if (ecgChartRef.current) {
      createChart();
    }

    return () => {
      if (chart) {
        chart.destroy(); // Cleanup chart instance on unmount
      }
    };
  }, [patient?.vitals?.ecg_clean]); // Recreate chart only when data changes

  useEffect(() => {
    if (chart && patient?.vitals?.ecg_clean?.length > 0) {
      const interval = setInterval(() => {
        updateChart();
      }, 15);

      return () => clearInterval(interval);
    }
  }, [chart, patient?.vitals?.ecg_clean]);

  return (
    <div>
      <canvas
        id={'ecg-chart-' + patient?.patient_id}
        width={695}
        height={180}
        style={{
          display: 'block',
          boxSizing: 'border-box',
          height: '180px',
          width: '695px',
          marginTop: '20px',
        }}
        ref={ecgChartRef}
      ></canvas>
    </div>
  );
};

export default HeartRateChart;
