//UserService.js
import {invokeApi} from "../utils/httpService";
import {ENDPOINT} from "../config/endpoint";

// Function to handle user login
export const login = async (data) => {
    const response = await invokeApi(ENDPOINT.login, 'POST', data);
    if(response){
        return response;
    } else {
        return false;
    }
}

export const requestOtp = async (data) => {
    const response = await invokeApi(ENDPOINT.sendResetPassword+'?email_or_mobile='+data, 'GET', data);
    if(response){
        return response;
    } else {
        return false;
    }
}

export const verifyOtp = async (data) => {
    const response = await invokeApi(ENDPOINT.verifyMobileOtp, 'POST', data);
    if(response){
        return response;
    } else {
        return false;
    }
}

export const verifyOtpForMobileReset = async (data) => {
    const response = await invokeApi(ENDPOINT.verifyOtp, 'POST', data);
    if(response){
        return response;
    } else {
        return false;
    }
}



export const updatePassword = async (token, data) => {
    const response = await invokeApi(ENDPOINT.resetPassword+'?token='+token, 'PUT', data);
    if(response){
        return response;
    } else {
        return false;
    }
}


export const rechangePassword = async (token, data) => {
    const response = await invokeApi(ENDPOINT.changePassword+'?token='+token, 'PUT', data);
    if(response){
        return response;
    } else {
        return false;
    }
}


// Function to send OTP to the user's mobile number
export const sendOtp = async (mobileNumber) => {
    const data = { username: mobileNumber };  // Prepare the request body
    const response = await invokeApi(ENDPOINT.sendVerifyMobile, 'post', data);
    if (response) {
        return response;
    } else {
        return false;
    }
};


// Function to send verification email
export const sendVerificationEmail = async (email) => {
    try {
        const response = await invokeApi(
            `${ENDPOINT.sendVerifyEmail}?email=${encodeURIComponent(email)}`,
            'POST'
        );
        if (response && response.status === 200) {
            return true;  
        } else {
            return false;
        }
    } catch (error) {
        // Log any errors encountered during the API request
        console.error("Failed to send email OTP", error);
        return false;
    }
};

export const verifyEmailOtp = async (emailVerificationToken) => {
    try {
        // Append the OTP token from the email verification process
        const url = `${ENDPOINT.verifyEmail}?token=${emailVerificationToken}`;
        const response = await invokeApi(url, 'PUT');
        return response || false;
    } catch (error) {
        return false;
    }
};

export const verifyEmailToken = async (token) => {
    // Construct the API endpoint with the token as a query parameter
    const url = `${ENDPOINT.verifyEmail}?token=${token}`;
    const response = await invokeApi(url, 'PUT');
    return response || false;
};





